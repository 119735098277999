// React & Dependencies
import React from 'react';

import { string, shape, bool, func } from 'prop-types';

// RecommendationsFE Components
import CarouselByType from '@recommendations-fe/carousel-by-type';

import {
  RecommendationsListWithoutContainer,
  RecommendationsListDefault,
  RecommendationsListGrid,
} from '@recommendations-fe/list';

import {
  RecommendationsCardHorizontalTitleFirst,
  RecommendationsCardVerticalTitleFirst,
  RecommendationsCardVertical,
} from '@recommendations-fe/card';

import { DEVICE_TYPE, CARD_TYPE } from '../../../../utils/constants';

import withTracker from '../with-tracker';
import IconFactory from '../recommendations-icons/icon-factory';

// Homes Components
import Section from '../section';
import { openNativeModal } from '../../../../utils/webkit-events';

const MoreLikeThisTemplate = {
  'more-like-this-recommendations': RecommendationsCardVerticalTitleFirst,
};

// Map for ByType
const carouselsMap = {
  list: ({ Card, ...props }) => (
    <RecommendationsListWithoutContainer
      Card={Card || RecommendationsCardHorizontalTitleFirst}
      ListComponent={RecommendationsListDefault}
      listType={CARD_TYPE.DEFAULT}
      {...props}
    />
  ),
  grid: ({ id, Card, ...rest }) => (
    <RecommendationsListWithoutContainer
      Card={Card || (MoreLikeThisTemplate[id] ?? RecommendationsCardVertical)}
      ListComponent={RecommendationsListGrid}
      listType={CARD_TYPE.GRID}
      id={id}
      {...rest}
    />
  ),
  default: ({ Card, ...props }) => (
    <RecommendationsListWithoutContainer
      Card={Card || RecommendationsCardHorizontalTitleFirst}
      ListComponent={RecommendationsListDefault}
      listType={CARD_TYPE.DEFAULT}
      {...props}
    />
  ),
};

// get Type in case carousel_type is not coming
const getType = (id) => {
  const idType = id?.split('-')?.pop();
  if (idType === CARD_TYPE.GRID || idType === CARD_TYPE.LIST) return idType;
  return CARD_TYPE.LIST;
};

const namespace = 'ui-recommendations-list';

const RecommendationsFeList = ({
  id,
  carousel_type,
  country,
  recommendation_data,
  is_polycard = false,
  lazy = false,
  deviceType = DEVICE_TYPE.MOBILE,
  handleSaveRenderTrack = null,
  shouldTrackOnPrint,
  shouldTrackOnView,
  title_tag,
  ...rest
}) => {
  const type = carousel_type
    ?? recommendation_data?.recommendation_info?.carousel_type
    ?? getType(id);
  const items_old_data = { recommendation_info: { recommendations: rest.items } };
  const cardType = type === CARD_TYPE.GRID ? CARD_TYPE.GRID : CARD_TYPE.LIST;
  return (
    <Section className={`${namespace}-section`} testId={id}>
      <CarouselByType
        id={id}
        IconFactory={IconFactory}
        carousels={carouselsMap}
        hideRebates
        carouselType={type}
        apiresponse={recommendation_data || items_old_data}
        is_polycard={is_polycard}
        titleTag={title_tag}
        onTitleTagIconClick={({ values }) => openNativeModal(values)}
        lazy={lazy}
        deviceType={deviceType}
        contextProps={{
          thousandSeparator: country?.thousands_separator,
          decimalSeparator: country?.decimal_separator,
        }}
        {...rest}
        onTrack={handleSaveRenderTrack}
        shouldTrackOnPrint={shouldTrackOnPrint}
        shouldTrackOnView={shouldTrackOnView}
        cardType={cardType}
      />
    </Section>
  );
};

RecommendationsFeList.propTypes = {
  id: string,
  recommendation_data: shape({}),
  carousel_type: string,
  country: shape({}),
  is_polycard: bool,
  deviceType: string,
  lazy: bool,
  handleSaveRenderTrack: func,
  shouldTrackOnPrint: bool,
  shouldTrackOnView: bool,
  title_tag: shape({}),
};

export default withTracker(RecommendationsFeList);

